@import "./mixins.scss";
@import "./variables.scss";

html {
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  font-size: 16px;
  font-style: inherit;
  font-weight: inherit;
  margin: 0;
  outline: 0;
  padding: 0;
  vertical-align: baseline;
}

body {
  font-family: 'amaranthregular';
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  height: 100%;
  width: 100%;
  -webkit-user-select:  auto;
  /* Safari */
  -ms-user-select:  text;
  /* IE 10 and IE 11 */
  user-select:  text;
  /* Standard syntax */
}

html,
body {
  height: 100%;
  margin: 0;
  scroll-behavior: smooth;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  overflow: auto;

  main {
    flex: 1;
    display: flex;
    flex-direction: column;

    .page-content {
      flex: 1;
    }
  }


}

html::-webkit-scrollbar-thumb {
  background: #003e7e;
  border-radius: 5px;
}

/* Track */
html::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

html::-webkit-scrollbar {
  width: 10px;

  @include mobile {
    display: none;
  }
}

main {
  background: $jum-white;
  height: calc(100vh - 100px);
  padding-top: 100px;
  position: relative;
  flex: 1;
}

button:disabled {
  border: none !important;
}

#toast-container {
  .toast-success {
    background-color: $success-color;
    opacity: 1;
    box-shadow: none;
    font-size: 1rem;
    letter-spacing: 1.5px;

    &:hover {
      box-shadow: none;
    }
  }

  .toast-warning {
    background-color: $btn-color;
    opacity: 1;
    box-shadow: none;
    font-size: 1rem;
    letter-spacing: 1.5px;

    &:hover {
      box-shadow: none;
    }
  }
}

.toast-top-right {
  top: 10px;
  @include fontTitle;
}

input:-webkit-autofill {
  background-color: $transparent;
}

input {
  letter-spacing: 1px;

  &::-webkit-autofill {
    background-color: $jum-white !important;
  }

  &::-internal-autofill-selected {
    background-color: $jum-white !important;
  }
}

.addonSlider {

  .slick-arrow.slick-prev,
  .slick-arrow.slick-next {
    background: $primary-three;
    border-radius: 50%;
    border: 3px solid $primary-three;
    top: 50%;
    width: 30px;
    height: 30px;

    img {
      height: 100%;
      width: 100%;
    }
  }

  .slick-arrow.slick-prev {
    left: 1%;
    // transform: rotate(180deg);
  }

  .slick-arrow.slick-next {
    right: 1%;
  }

  .slick-track {
    .slick-slide {
      >div {
        width: 100%;
        margin: auto;
        display: flex;
        justify-content: center;

        @include midmobile {
          margin: auto;
          width: 80%;
        }
      }
    }
  }
}

#root {
  .app-loader {
    background: rgba(255, 255, 255, 0.8);
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    z-index: 9998;
    display: grid;
    place-items: center;

    .app-spinner {
      position: relative;
      width: 10px;
      height: 10px;
      border-radius: 5px;
      background-color: transparent;
      color: transparent;
      box-shadow: 0 -18px 0 0 $logo-color,
        12.727926px -12.727926px 0 0 $logo-color, 18px 0 0 0 $logo-color,
        12.727926px 12.727926px 0 0 rgba(152, 128, 255, 0),
        0 18px 0 0 rgba(152, 128, 255, 0),
        -12.727926px 12.727926px 0 0 rgba(152, 128, 255, 0),
        -18px 0 0 0 rgba(152, 128, 255, 0),
        -12.727926px -12.727926px 0 0 rgba(152, 128, 255, 0);
      animation: dot-spin 1.5s infinite linear;
    }

    @keyframes dot-spin {

      0%,
      100% {
        box-shadow: 0 -18px 0 0 $logo-color,
          12.727926px -12.727926px 0 0 $logo-color, 18px 0 0 0 $logo-color,
          12.727926px 12.727926px 0 -5px rgba(152, 128, 255, 0),
          0 18px 0 -5px rgba(152, 128, 255, 0),
          -12.727926px 12.727926px 0 -5px rgba(152, 128, 255, 0),
          -18px 0 0 -5px rgba(152, 128, 255, 0),
          -12.727926px -12.727926px 0 -5px rgba(152, 128, 255, 0);
      }

      12.5% {
        box-shadow: 0 -18px 0 -5px rgba(152, 128, 255, 0),
          12.727926px -12.727926px 0 0 $logo-color, 18px 0 0 0 $logo-color,
          12.727926px 12.727926px 0 0 $logo-color,
          0 18px 0 -5px rgba(152, 128, 255, 0),
          -12.727926px 12.727926px 0 -5px rgba(152, 128, 255, 0),
          -18px 0 0 -5px rgba(152, 128, 255, 0),
          -12.727926px -12.727926px 0 -5px rgba(152, 128, 255, 0);
      }

      25% {
        box-shadow: 0 -18px 0 -5px rgba(152, 128, 255, 0),
          12.727926px -12.727926px 0 -5px rgba(152, 128, 255, 0),
          18px 0 0 0 $logo-color, 12.727926px 12.727926px 0 0 $logo-color,
          0 18px 0 0 $logo-color,
          -12.727926px 12.727926px 0 -5px rgba(152, 128, 255, 0),
          -18px 0 0 -5px rgba(152, 128, 255, 0),
          -12.727926px -12.727926px 0 -5px rgba(152, 128, 255, 0);
      }

      37.5% {
        box-shadow: 0 -18px 0 -5px rgba(152, 128, 255, 0),
          12.727926px -12.727926px 0 -5px rgba(152, 128, 255, 0),
          18px 0 0 -5px rgba(152, 128, 255, 0),
          12.727926px 12.727926px 0 0 $logo-color, 0 18px 0 0 $logo-color,
          -12.727926px 12.727926px 0 0 $logo-color,
          -18px 0 0 -5px rgba(152, 128, 255, 0),
          -12.727926px -12.727926px 0 -5px rgba(152, 128, 255, 0);
      }

      50% {
        box-shadow: 0 -18px 0 -5px rgba(152, 128, 255, 0),
          12.727926px -12.727926px 0 -5px rgba(152, 128, 255, 0),
          18px 0 0 -5px rgba(152, 128, 255, 0),
          12.727926px 12.727926px 0 -5px rgba(152, 128, 255, 0),
          0 18px 0 0 $logo-color, -12.727926px 12.727926px 0 0 $logo-color,
          -18px 0 0 0 $logo-color,
          -12.727926px -12.727926px 0 -5px rgba(152, 128, 255, 0);
      }

      62.5% {
        box-shadow: 0 -18px 0 -5px rgba(152, 128, 255, 0),
          12.727926px -12.727926px 0 -5px rgba(152, 128, 255, 0),
          18px 0 0 -5px rgba(152, 128, 255, 0),
          12.727926px 12.727926px 0 -5px rgba(152, 128, 255, 0),
          0 18px 0 -5px rgba(152, 128, 255, 0),
          -12.727926px 12.727926px 0 0 $logo-color, -18px 0 0 0 $logo-color,
          -12.727926px -12.727926px 0 0 $logo-color;
      }

      75% {
        box-shadow: 0 -18px 0 0 $logo-color,
          12.727926px -12.727926px 0 -5px rgba(152, 128, 255, 0),
          18px 0 0 -5px rgba(152, 128, 255, 0),
          12.727926px 12.727926px 0 -5px rgba(152, 128, 255, 0),
          0 18px 0 -5px rgba(152, 128, 255, 0),
          -12.727926px 12.727926px 0 -5px rgba(152, 128, 255, 0),
          -18px 0 0 0 $logo-color, -12.727926px -12.727926px 0 0 $logo-color;
      }

      87.5% {
        box-shadow: 0 -18px 0 0 $logo-color,
          12.727926px -12.727926px 0 0 $logo-color,
          18px 0 0 -5px rgba(152, 128, 255, 0),
          12.727926px 12.727926px 0 -5px rgba(152, 128, 255, 0),
          0 18px 0 -5px rgba(152, 128, 255, 0),
          -12.727926px 12.727926px 0 -5px rgba(152, 128, 255, 0),
          -18px 0 0 -5px rgba(152, 128, 255, 0),
          -12.727926px -12.727926px 0 0 $logo-color;
      }
    }
  }
}

.Female {
  background: #fe00ae;
}

.Male {
  background: #003e7e;
}

.rental {
  background: #003e7e;
}

// informationList social icons
// .social-icons-small {
//   display: flex;
//   gap: 1.25rem;

//   @include tablet {
//     justify-content: start;
//   }

//   @include tabletNew {
//     justify-content: center;
//   }
// }

.blueBackGround {
  background: $logo-color !important;
}

.cartBtnLoader {
  .wrapper {
    height: 40px;
  }
}

.popup-overlay {
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  background: $logo-color;
  width: 40vw;
  padding: 2rem 1rem;
  z-index: 10;
  border-radius: 12px;
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.15);

  @include mobile {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: calc(100vw - 2rem);
    height: fit-content;
  }

  .popup-content {
    position: relative;

    h2 {
      @include fontTitle;
      color: $jum-white;
    }

    p {
      color: $jum-white;
      letter-spacing: 1px;
      text-transform: uppercase;
    }

    .close-icon {
      background-color: transparent;
      border: none;
      outline: none;
      position: absolute;
      top: 1rem;
      right: 1rem;
      transform: translate(50%, -50%);

      &:focus {
        box-shadow: none;
        outline: none;
      }
    }
  }
}

.lazy-bg-image {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  /* You can set a placeholder background color or image here */
}