a[target='_blank']
/* body, html {
  background-color: #003057 !important;
} */
/*
  Hides Sitecore Experience Editor markup,
  if you run the app in connected mode while the Sitecore cookies
  are set to edit mode.
*/
.scChromeData, .scpm {
  display: none !important;
}

body{
   -webkit-user-select: auto; /* Safari */
  -ms-user-select:  text ; /* IE 10 and IE 11 */
  user-select:  text; /* Standard syntax */
}
@font-face {
  font-family: "MyriadPro-Bold";
  src: url("./fonts/MydriadPro/MyriadPro-BoldCond.eot");
  src: url("./fonts/MydriadPro/MyriadPro-BoldCond.eot?#iefix")
      format("embedded-opentype"),
    url("./fonts/MydriadPro/MyriadPro-BoldCond.woff2") format("woff2"),
    url("./fonts/MydriadPro/MyriadPro-BoldCond.woff") format("woff"),
    url("./fonts/MydriadPro/MyriadPro-BoldCond.ttf") format("truetype"),
    url("./fonts/MydriadPro/MyriadPro-BoldCond.svg#MyriadPro-BoldCond")
      format("svg");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "MyriadPro-Regular";
  src: url("./fonts/MydriadPro/MyriadPro-Cond.eot");
  src: url("./fonts/MydriadPro/MyriadPro-Cond.eot?#iefix")
      format("embedded-opentype"),
    url("./fonts/MydriadPro/MyriadPro-Cond.woff2") format("woff2"),
    url("./fonts/MydriadPro/MyriadPro-Cond.woff") format("woff"),
    url("./fonts/MydriadPro/MyriadPro-Cond.ttf") format("truetype"),
    url("./fonts/MydriadPro/MyriadPro-Cond.svg#MyriadPro-Cond") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
/* TfArrow fonts*/
@font-face {
    font-family:'TFArrow-Regular';
    src: url('./fonts/TFArrow/tfarrowmedium-webfont.woff2') format('woff2'),
         url('./fonts/TFArrow/tfarrow-medium-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family:'TFArrow-Medium';
    src: url('./fonts/TFArrow/tfarrowmedium-webfont.woff2') format('woff2'),
         url('./fonts/TFArrow/tfarrow-medium-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
  font-family:'TFArrow-Light';
  src: url('./fonts/TFArrow/tf-arrow-light-1361518756.eot'); /* IE9 Compat Modes */
  src: url('./fonts/TFArrow/tf-arrow-light-1361518756.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./fonts/TFArrow/tf-arrow-light-1361518756.woff') format('woff'), /* Modern Browsers */
       url('./fonts/TFArrow/tf-arrow-light-1361518756.ttf')  format('truetype'), /* Safari, Android, iOS */
       url('./fonts/TFArrow/tf-arrow-light-1361518756.svg#8352247130da24fc44911e736efbefdc') format('svg'); /* Legacy iOS */
       
  font-style:   normal;
  font-weight:  200;
}
@font-face {
  font-family:'TFArrow-Extrabold';
  src: url('./fonts/TFArrow/tf-arrow-extrabold-1361518762.eot'); /* IE9 Compat Modes */
  src: url('./fonts/TFArrow/tf-arrow-extrabold-1361518762.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./fonts/TFArrow/tf-arrow-extrabold-1361518762.woff') format('woff'), /* Modern Browsers */
       url('./fonts/TFArrow/tf-arrow-extrabold-1361518762.ttf')  format('truetype'), /* Safari, Android, iOS */
       url('./fonts/TFArrow/tf-arrow-extrabold-1361518762.svg#7e2ff9996867b71989d3c13ed111f778') format('svg'); /* Legacy iOS */
  font-style:   normal;
  font-weight:  700;
}
/* End TfArrow */
/* Amaranth Fonts */
@font-face {
  font-family: 'amaranthbold';
  src: url('./fonts/Amaranth/amaranth-bold-webfont.woff2') format('woff2'),
    url('./fonts/Amaranth/amaranth-bold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'amaranthbold_italic';
  src: url('./fonts/Amaranth/amaranth-bolditalic-webfont.woff2') format('woff2'),
    url('./fonts/Amaranth/amaranth-bolditalic-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'amaranthitalic';
  src: url('./fonts/Amaranth/amaranth-italic-webfont.woff2') format('woff2'),
    url('./fonts/Amaranth/amaranth-italic-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'amaranthregular';
  src: url('./fonts/Amaranth/amaranth-regular-webfont.woff2') format('woff2'),
    url('./fonts/Amaranth/amaranth-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}
a:hover {
  text-decoration: none;
}
