// Define all the variables here

/* :: screen widths */
$xxs-min-width: 325px;
$xxs-max-width: 576px;
$xs-min-width: 577px;
$xs-max-width: 767px;
$sm-min-width: 768px;
$sm-max-width: 991px;
$md-min-width: 992px;
$mdd-min-width: 1025px;
$mdd-max-width: 1024px;
$md-max-width: 1199px;
$lg-min-width: 1200px;
$lg-max-width: 1439px;
$xlg-min-width: 1440px;
$xxlg-min-width: 1800px;
$xxlg-max-width: 1920px;

/* :: color variables */
$jum-black: #000000;
$jum-gold-1: #7c6f55;
$jum-grey: #8f8f8f;
$jum-light-grey: #f6f6f6;
$jum-grey-trans: rgba(143, 143, 143, 0.15);
$jum-white: #ffffff;

// new color combinations
// Primarily Colors
$primary-one: #aadef0;
$primary-two: #0071c2;
$primary-three: #f6ce3a;
$primary-four: #3eb6df;
$success-color: #60c878;
$success-coupon: #36ff36;
$logo-color: #003e7e;
$danger-color: #ed6f74;
// Waterpark Environment Colors

// Secondary Colors
$secondary-one: #c79734;
$secondary-two: #9d6330;
$secondary-three: #6c4335;
// Play structure color
// $secondary-four: #f6ce3a;
$secondary-five: #fe00ae;
$secondary-six: #05d0bd;
$transparent: transparent;

// extra color shades
$extra-one: #42c3ff;
$extra-two: #6f8be2;
$extra-three: #9cffed;

// btn color code
$btn-color: #e1ad01;
$mustard-color: #ffe45c;
$blue-one: #2ecbe9;
$blue-two: #128fc8;
$blue-three: #03a9f4;
$blue-four: #00b0ff;
$blue-five: #0091ea;
$blue-six: #00b0ff;
$blue-seven: #00b0ff;
$cyan-one: #00e5ff;
$cyan-two: #80deea;
$jum-gray: #e0e0e0;
$male-bg: #89cff0;
$female-bg: #e8abb4;
// $sky-blue: #87ceeb;
$light-sky-blue: #87cefa;