@import "./variables.scss";

/*mixins declared below*/
@mixin extrasmallmobile {
  @media (max-width: #{$xxs-min-width}) {
    @content;
  }
}

@mixin smallmobile {
  @media (max-width: #{$xs-min-width}) {
    @content;
  }
}

@mixin midmobile {
  @media (max-width: #{$xxs-max-width}) {
    @content;
  }
}

@mixin mobile {
  @media (max-width: #{$xs-max-width}) {
    @content;
  }
}

@mixin tablet {
  @media (max-width: #{$sm-max-width}) {
    @content;
  }
}

@mixin tabletNew {
  @media (min-width: #{$sm-min-width}) and(max-width:#{$md-max-width}) {
    @content;
  }
}

@mixin desktopsmall {
  @media (min-width: #{$md-min-width}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$lg-min-width}) {
    @content;
  }
}

@mixin desktoplarge {
  @media (min-width: #{$xlg-min-width}) {
    @content;
  }
}

@mixin desktopxtralarge {
  @media (min-width: #{$xxlg-min-width}) {
    @content;
  }
}

@mixin laptopScreen {
  @media (min-width: #{$md-max-width}) and(max-width:#{$xlg-min-width}) {
    @content;
  }
}


@mixin desktopxxtralarge {
  @media (min-width: #{$xxlg-max-width}) {
    @content;
  }
}

@mixin fontTitle {
  font-family: "TFArrow-Medium";
  font-weight: 500;
  letter-spacing: 1px;
}

@mixin fontRegularBold {
  font-family: "TFArrow-Medium";
  letter-spacing: 1px;
  font-weight: 600;
}

@mixin fontTitleBold {
  font-family: "TFArrow-Extrabold";
  letter-spacing: 1px;
  font-weight: 700;
}

@mixin fontParagraph {
  font-family: "TFArrow-Regular";
  font-weight: 400;
  letter-spacing: 1px;
}

// Amaranth font

@mixin fontParagraph {
  font-family: "amaranthregular";
  font-weight: 400;
  letter-spacing: 1px;
}

@mixin fontTitle {
  font-family: "amaranthregular";
  font-weight: 500;
  letter-spacing: 1px;
}

@mixin fontRegularBold {
  font-family: "amaranthbold";
  letter-spacing: 1px;
  font-weight: 600;
}

@mixin fontTitleBold {
  font-family: "amaranthbold";
  letter-spacing: 1px;
  font-weight: 700;
}